import DBStore from "@/core/db_store";
import Swal from "sweetalert2";
import { computed, h, nextTick, reactive, ref, watch } from "vue";
import { edit } from "@/core/db";
import { openPanel } from '@/layouts/layouts';
import { getModel } from "@/tables";
import { genGUID, isAsync, mergeDeep } from "@/helpers/utils";
import Export from "./Export.vue";
import { tableExport } from "@/api/db";
import { query } from "@/api/db";

export default class DBGridController {
	public store: DBStore;
	public form: any;
	public field: any;
	public verified: any;
	public valid: any;
	public feedback: any;
	public readonly: any;
	public access: any;
	public settings: any;
	public panelFun: any;
	public standardCommands: any;
	public pages: any;
	public page: any;
	public limit: any;
	public searchText: any;
	public stateField: any;
	public rows: any;
	public sortingFields: any;
	public footerData: any;

	constructor(protected props: any, protected emit: any) {
		this.verified = ref(false);
		this.valid = ref(false);
		this.feedback = ref('');
		this.searchText = ref('');
		this.pages = ref(0);
		this.page = ref(0);
		this.panelFun = ref([]);
		this.rows = ref([]);
		this.sortingFields = reactive({});

		this.footerData = reactive({});

		this.form = props.form;
		this.field = props.field;

		this.store = props.store ? props.store : this.createStore();

		if (!this.store.model.access) {
			this.store.model.access = {
				read: false,
				create: false,
				update: false,
				delete: false
			}
		}

		this.access = this.store.model.access;

		this.readonly = computed(
			() => {
				return !(this.access.create || this.access.update || this.access.delete) ||
					props.readonly ||
					this.store.state.readonly ||
					props?.form?.readonly?.value ? true : false
			}
		);

		this.settings = reactive({
			id: null,
			filters: [],
			orderBy: {},
			menu: true,
			numbering: false,
			sorting: false,
			slectMode: false,
			searchpanel: true,
			limits: [25, 50, 75, 100],
			limit: 25,
			owner: null,//id - зависимого поля
			panelFun: true,
			footer: false,
			actions: {
				view: false,
				edit: true,
				create: true,
				copy: true,
				delete: true
			},
			contextMenuBody: [],
			contextMenuHead: [],
			default: {},
			localStorageKey: null,
			edit: {},//Настройки компонента редактирования
			height: null,
			autoOpen: true,
			deleteText: 'Удалить запись?',
			crossot: () => false,//Перечеркивание
			export: true,
			transparentTr: false,
			styleTr: () => ({}),
			classTr: () => ({}),
			rowSelect: false,
			onCreate: () => null,
			onRowSelect: (data: any, checked: boolean) => {
				props.store.selectRow(data, checked);
			},
			onBeforeNew: async (params: any) => await edit(params),
			onBeforeEdit: async (params: any) => await edit(params),
			onBeforeCopy: async (params: any) => await edit(params),
			onKeyDown: async () => true,
			cellClick: {}
		});

		Object.assign(this.settings, props.config);

		this.limit = ref(this.settings.limit);

		if (Object.keys(this.settings.orderBy).length > 0) {
			Object.assign(this.sortingFields, this.settings.orderBy);

			this.onSort(this.settings.orderBy, false);
		}

		this.standardCommands = {
			edit: {
				caption: 'Изменить',
				title: 'Изменить',
				icon: <i class="icon icon-edit"></i>,
				class: 'btn btn-edit',
				onClick: () => this.editRow()
			},
			view: {
				caption: 'Просмотр',
				title: 'Просмотр',
				icon: <i class="icon icon-eye"></i>,
				class: 'btn btn-edit',
				onClick: () => this.editRow()
			},
			add: {
				caption: 'Добавить',
				title: 'Добавить',
				icon: <i class="icon icon-plus-circle"></i>,
				class: 'btn btn-add',
				onClick: () => this.addRow()
			},
			copy: {
				caption: 'Копировать',
				title: 'Копировать',
				icon: <i class="icon icon-file"></i>,
				class: 'btn btn-copy',
				onClick: () => this.copyRow()
			},
			delete: {
				caption: 'Удалить',
				title: 'Удалить',
				icon: <i class="icon icon-x-circle"></i>,
				class: 'btn btn-delete',
				onClick: () => this.deleteRow()
			}
		}

		nextTick(() => {
			if (this.readonly.value) {
				this.createPanelFun([
					{
						view: this.settings.view ? {} : null
					}
				])
			} else {
				this.createPanelFun([
					{
						edit: this.access.update && this.settings.actions.edit ? {} : null,
						add: this.access.create && this.settings.actions.create ? {} : null,
						copy: this.access.create && this.settings.actions.copy ? {} : null,
						delete: this.access.delete && this.settings.actions.delete ? {} : null
					}
				])
			}
		})

		watch(
			() => this.searchText.value,
			async () => await this.fetchData({ page: 0 })
		)

		watch(
			() => this.limit.value,
			async () => await this.fetchData({ page: 0 })
		)
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	createStore() {
		// обновлять позиции
		const field = mergeDeep({}, this.getFieldModel(this.field.split('.'), this.form.store.state));

		const store = new DBStore(field?.table ? field.table : `table_${this.field}`)
		store.model = field.type.table;

		this.stateField = this.getFieldModel(this.field.split('.'), this.form.store.state);

		store.createState(this.stateField);

		const access = this.form.store.model.access ? this.form.store.model.access : {
			read: true,
			create: true,
			update: true,
			delete: true
		};

		const editable = 'access' in this.stateField ? store.availabeRole(this.stateField.access, this.field, field) : access.create || access.update || access.delete;

		store.model.access = {
			read: true,
			create: editable,
			update: editable,
			delete: editable
		}

		if (field.depends) {
			store.model.depends = field.depends;

			store.owner = this.form.store.getValue(field.depends);

			if (!store.owner) {
				store.owner = this.form.store.setValue(field.depends, genGUID());
			}
		}

		const data = this.form.store.getValue(this.field);

		if (data) {
			store.data.rows = data;
			store.data.position = data.length ? 0 : -1;
		} else {
			const newData = this.form.store.setValue(this.field, []);

			store.data.rows = newData;
			store.data.position = newData.length ? 0 : -1;
		}

		store.files = this.form.store.files;

		return store;
	}

	createPanelFun(items: any) {
		this.panelFun.value.splice(0, this.panelFun.value.length);

		for (const item of items) {
			const panel: any = {};

			for (const key in item) {
				if (item[key]) {
					panel[key] = {};

					if (key in this.standardCommands) {
						Object.assign(panel[key], this.standardCommands[key], item[key]);
					} else {
						Object.assign(panel[key], item[key]);
					}
				}
			}

			this.panelFun.value.push(panel);
		}
	}

	validation() {
		if (this.form && this.field) {
			const field = this.form.store.state.fields[this.field];

			if (this.props.validation && field.validation) {
				for (const i in field.validation) {
					const result = field.validation[i](this.store.data.rows);
					if (result !== false) {
						this.verified.value = true;
						this.valid.value = false;
						this.feedback.value = result;

						return result;
					}
				}
			}
		}

		this.verified.value = false;
		this.valid.value = true;
		this.feedback.value = '';

		return false;
	}

	home() {
		this.activeRow(0);
	}

	end() {
		this.activeRow(this.store.data.rows.length - 1)
	}

	focusRow() {
		const position = this.store.data.position;
		if (position != -1 && this.store.data.rows.length > position && this.rows.value[position]) {
			this.rows.value[position].focus();
		}
	}

	activeRow(position: number) {
		this.store.setPosition(position);

		this.focusRow();

		this.emit('active', this.store.data.rows[position]);
	}

	positionsUpdate() {
		const model = this.store.model;

		if (model.subtable && model.positionField) {
			const rows = this.store.data.rows;

			for (const postion in rows) {
				rows[postion][model.positionField] = Number(postion) + 1;
			}
		}
	}

	async addRow(data = {}) {
		if (!this.readonly.value && this.access.create) {
			const response: any = await query({
				table: this.store.name,
				method: 'new',
				data: {
					...	typeof this.settings.default == 'function' ? (isAsync(this.settings.default) ? await this.settings.default() : this.settings.default()) : this.settings.default,
					...data
				}
			});

			const params = Object.assign({
				store: this.store,
				data: response.data,
				config: this.settings.edit
			});

			if (this.store.model.ownerField) {
				if (this.store.owner) {
					params.data.owner = this.store.owner;
				} else {
					console.error('Не указан владелец');

					return;
				}
			}

			await this.settings.onBeforeNew(params);

			this.positionsUpdate();

			this.focusRow();

			this.verified.value && this.validation();
		}
	}

	async copyRow(defaults = {}) {
		//Возвращать фокус на грид
		const data = this.store.currentData();
		if (data) {
			if (!this.readonly.value && this.access.create) {
				await this.settings.onBeforeCopy({
					copy: true,
					store: this.store,
					data,
					config: this.settings.edit,
					defaults
				});

				this.positionsUpdate();

				this.verified.value && this.validation();

				this.focusRow();
			}
		}
	}

	async openRow(data: any) {
		const params: any = Object.assign({
			store: this.store,
			data,
			config: this.settings.edit
		});

		if (this.store.model.ownerField) {
			if (this.store.owner) {
				params.data.owner = this.store.owner;
			} else {
				console.error('Не указан владелец');

				return;
			}
		}

		if (this.settings.actions.view || this.readonly.value) {
			params.readonly = true;

			await this.settings.onBeforeEdit(params);

			this.positionsUpdate();

			this.focusRow();
		} else {
			if (!this.readonly.value && this.access.update) {
				params.owner = params.store.owner;

				await this.settings.onBeforeEdit(params);

				this.positionsUpdate();

				this.focusRow();

				this.verified.value && this.validation();
			}
		}
	}

	async editRow() {
		//Возвращать фокус на грид
		const data = this.store.currentData();

		if (data) this.openRow(data);
	}

	async deleteRow() {
		if (!this.readonly.value && this.access.delete) {
			const data = this.store.currentData();
			if (data) {
				if (!this.store?.model || await this.store.model.onBeforeDelete(data)) {
					Swal.fire({
						title: this.settings.deleteText,
						showCancelButton: true,
						confirmButtonText: 'Да',
						cancelButtonText: 'Отмена'
					}).then(async ({ value }) => {
						if (value) {
							await this.store.deleteRecord(data);

							this.positionsUpdate();

							this.store?.model && await this.store.model.onAfterDelete(data);

							this.verified.value && this.validation();

							this.focusRow();
						}
					})
				}
			}
		}
	}

	selectRow(data: any = this.store.currentData()) {
		if (this.settings.slectMode) {
			this.emit('select', data);
		} else {
			this.editRow();
		}
	}

	setFields(fields: any = []) {
		const config: any = {};

		if (fields.length > 0) {
			config.fields = {};

			for (const field of fields) {
				if (this.store.state.fields[field.name]) {
					config.fields[field.name] = Object.assign(
						{},
						this.store.state.fields[field.name] ? this.store.state.fields[field.name] : {}
					);

					Object.assign(config.fields[field.name], { description: field.description });

					config.fields[field.name].config.visible = field.visible;
				}
			}
		} else {
			//При сбросе настроек отображаются все колонки
			const schema = getModel(this.store.name);
			if (schema) {
				const _fields = this.props.config?.fields;

				if (_fields) config.fields = mergeDeep({}, _fields);
			}
		}

		this.store.createState(config);
	}

	contextMenuHead() {
		const items = [];

		if (!this.store.model.subtable) {
			for (const key in this.store.state.fields) {
				const field = this.store.state.fields[key];
				if (field?.config?.sorting) {
					items.push({
						icon: <i class="icon icon-arrow-loop"></i>,
						caption: 'Сброс сортировки',
						onClick: async () => await this.resetSorting()
					})

					break;
				}
			}

			items.push({
				icon: <i class="icon icon-arrow-sync"></i>,
				caption: 'Обновить',
				onClick: async () => await this.fetchData()
			})
		}

		if (this.settings.localStorageKey) {
			items.push({
				icon: <i class="icon icon-cog"></i>,
				caption: 'Настройка таблицы',
				onClick: async () => {
					openPanel({
						scrollable: true,
						modal: true,
						caption: "Настройка таблицы",
						onCreate: (panel: any) => ({
							component: h(Export, { fields: this.store.state.fields }),
							buttons: [
								{
									class: "btn btn-action",
									caption: 'Сохранить',
									onClick: () => {
										const config = panel.ref.getFields();

										localStorage[this.settings.localStorageKey] = JSON.stringify(config);

										this.setFields(config);

										panel.close();
									}
								},
								{
									class: "btn btn-action",
									caption: 'Сброс настроек',
									onClick: () => {
										localStorage.removeItem(this.settings.localStorageKey);

										this.setFields();

										panel.close();
									}
								}
							]
						})
					})
				}
			})
		}

		if (this.settings.export && !this.store.model.subtable) {
			items.push({
				icon: <i class="icon icon-export"></i>,
				caption: 'Экспорт таблицы',
				onClick: async () => {
					openPanel({
						modal: true,
						scrollable: true,
						caption: "Экспорт таблицы",
						onCreate: (panel: any) => ({
							component: h(Export, { fields: this.store.state.fields }),
							buttons: [
								{
									class: "btn btn-action",
									caption: 'Экспорт',
									onClick: async () => {
										const options = {
											fields: panel.ref.getFields(),
											options: this.store.fetchParams()
										}

										const data: any = await tableExport(this.store.name, options)
										const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

										const link = document.createElement('a');

										link.download = 'export.xlsx';

										link.href = URL.createObjectURL(blob);

										link.click();

										URL.revokeObjectURL(link.href);

										panel.close();
									}
								}
							]
						})
					})
				}
			})
		}

		return items.concat(typeof this.settings.contextMenuHead == 'function' ? this.settings.contextMenuHead() : this.settings.contextMenuHead);
	}

	contextMenuBody(data: any) {
		const items = [];

		if (this.readonly.value || this.settings.actions.view) {
			items.push({
				icon: <i class="icon icon-eye"></i>,
				caption: 'Просмотр',
				onClick: () => this.editRow()
			})
		} else {
			if (this.settings.actions.edit && this.access.update)
				items.push({
					icon: <i class="icon icon-edit"></i>,
					caption: 'Изменить',
					onClick: () => this.editRow()
				})

			if (this.access.create) {
				if (this.settings.actions.create) {
					items.push({
						icon: <i class="icon icon-plus-circle"></i>,
						caption: 'Добавить',
						onClick: () => this.addRow()
					})
				}

				if (this.settings.actions.copy) {
					items.push({
						icon: <i class="icon icon-file"></i>,
						caption: 'Копировать',
						onClick: () => this.copyRow()
					});
				}
			}

			if (this.settings.actions.delete && this.access.delete)
				items.push({
					icon: <i class="icon icon-x-circle"></i>,
					caption: 'Удалить',
					onClick: () => this.deleteRow()
				})
		}

		return items.concat(typeof this.settings.contextMenuBody == 'function' ? this.settings.contextMenuBody(data) : this.settings.contextMenuBody);
	}

	async onKeyDown(event: any) {
		const rowData: any = this.store.data.rows[this.store.data.position] ? this.store.data.rows[this.store.data.position] : {}

		if (await this.settings.onKeyDown(event, rowData, this)) {
			switch (event.keyCode) {
				case 13://enter
					this.selectRow();

					break;

				case 32://space
					if (this.settings.rowSelect && this.store.data.position != null) {
						event.preventDefault();

						const id = rowData.id;

						this.settings.onRowSelect(rowData, !this.store.data.select.includes(id));
					}

					break;

				case 45://insert
					if (this.settings.actions.create)
						this.addRow();

					break;

				case 46://delete
					if (this.settings.actions.delete)
						this.deleteRow();

					break;

				case 113://F2
					if (this.settings.actions.edit)
						this.editRow();

					break;

				case 37://left
					event.preventDefault();

					if (event.ctrlKey) {
						if (this.page.value > 0) await this.fetchData({ page: this.page.value - 1 });
					} else this.home();

					this.focusRow();

					break;

				case 39://right
					event.preventDefault();

					if (event.ctrlKey) {
						if (this.page.value < this.pages.value - 1) await this.fetchData({ page: this.page.value + 1 });
					} else this.end();

					this.focusRow();

					break;

				case 38://up
					event.preventDefault();

					if (this.store.data.position != null && this.store.data.position > 0) {
						if (event.ctrlKey) {
							this.rowUp();
						} else {
							this.activeRow(this.store.data.position - 1);
						}

						this.focusRow();
					}
					break;

				case 40://down
					event.preventDefault();

					if (this.store.data.position != null && this.store.data.position < this.store.data.rows.length - 1) {
						if (event.ctrlKey) {
							this.rowDown();
						} else {
							this.activeRow(this.store.data.position + 1);
						}

						this.focusRow();
					}

					break;

				case 36://home
					event.preventDefault();

					if (event.ctrlKey) {
						await this.fetchData({ page: 0 });
					} else {
						this.home();
					}

					this.focusRow();

					break;

				case 35://end
					event.preventDefault();

					if (event.ctrlKey && this.pages.value > 0) {
						await this.fetchData({ page: this.pages.value - 1 });
					} else {
						this.end();
					}

					this.focusRow();

					break;
			}
		}
	}

	async onSort(sort: any, fetch = true) {
		this.store.fetchOptions.orderby = [];

		for (const key in sort) {
			const field: any = this.store.state.fields[key];

			this.store.fetchOptions.orderby.push(field.config.sorting + (sort[key] ? '' : ' desc'));
		}

		if (fetch) await this.fetchData();
	}

	async sort(sort: any, fetch = true) {
		for (const key in this.sortingFields) {
			delete this.sortingFields[key];
		}

		Object.assign(this.sortingFields, sort);

		this.onSort(this.sortingFields, fetch);
	}

	async selectPage(page: any) {
		await this.fetchData({ page });
	}

	sortByPosition() {
		const model = this.store.model;

		if (model.subtable && model.positionField) {
			const { rows } = this.store.data;

			rows.sort((a: any, b: any) => a[model.positionField] - b[model.positionField]);
		}
	}

	rowUp() {
		this.positionsUpdate();

		const model = this.store.model;

		if (model.subtable && model.positionField) {
			const { position, rows } = this.store.data;

			const posA = rows[position][model.positionField];
			const posB = rows[position - 1][model.positionField];

			rows[position][model.positionField] = posB;
			rows[position - 1][model.positionField] = posA;

			this.store.setPosition(position - 1);

			setTimeout(() => this.focusRow(), 0);
		}

		this.sortByPosition();
	}

	rowDown() {
		this.positionsUpdate();

		const model = this.store.model;

		if (model.subtable && model.positionField) {
			const { position, rows } = this.store.data;

			const posA = rows[position][model.positionField];
			const posB = rows[position + 1][model.positionField];

			rows[position][model.positionField] = posB;
			rows[position + 1][model.positionField] = posA;

			this.store.setPosition(position + 1);

			setTimeout(() => this.focusRow(), 0);
		}

		this.sortByPosition();
	}

	async resetSorting() {
		this.store.fetchOptions.orderby.splice(0, this.store.fetchOptions.orderby.length);

		for (const key in this.sortingFields) {
			delete this.sortingFields[key];
		}

		if (Object.keys(this.settings.orderBy).length > 0) {
			Object.assign(this.sortingFields, this.settings.orderBy);

			this.onSort(this.settings.orderBy, false);
		}

		await this.fetchData();
	}

	async fetchData(options: any = {}) {
		if ('page' in options) {
			this.page.value = options.page;

			this.store.fetchOptions.page = options.page;
		}

		this.store.fetchOptions.searchtext = this.searchText.value;
		this.store.fetchOptions.limit = this.limit.value;

		await this.store.fetchData(options);

		this.pages.value = this.store.pages;

		if ('page' in options) {
			this.store.fetchOptions.page = 0;
		} else {
			this.page.value = 0;
		}
	}
}