<template>
	<div class="v-dropdown-container" v-clickaway="clickawayClose" tabindex="-1" @keydown="keyDown">
		<div class="m-1" v-if="isSearchInput">
			<SearchInput v-model="searchValue" @keydown="onKeyDownFind" ref="searchInput" :searchAway="searchAway"></SearchInput>
		</div>

		<div class="no-found">
			<template v-if="!selectMode && (searchText || searchValue)">
				<div class="no-found-label" v-if="data.rows.length > 0">Поиск "{{ searchText || searchValue }}"</div>
				<div class="no-found-label" v-else>Не найдено "{{ searchText || searchValue }}"</div>
			</template>

			<a href="#" class="btn-create" @click="onCreate" title="Создать новый элемент" v-if="showCreateBtn && !selectMode">
				Создать
				<strong>(Ins)</strong>
			</a>
		</div>

		<ul class="ul-dropdown" v-if="data.rows.length > 0">
			<template v-for="(item, i) of data.rows" :key="i">
				<li tabindex="-1"
					:class="{ 'dropdown-item': true, active: data.position == i, 'd-block': true }"
					:title="notion(item).title"
					:ref="el => { if (el) items[i] = el }"
					@click="() => onClick(item)" v-html="notion(item).notion">
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import DBStore from "@/core/db_store";
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

export default defineComponent({
	components: {
		SearchInput
	},

	emits: ['close', 'set-data'],

	props: {
		table: {
			type: String,
			default: ''
		},
		valueField: {
			type: String,
			default: 'id'
		},
		textField: {
			type: String,
			default: 'name'
		},
		controller: {
			type: Object,
			default: () => ({})
		},
		searchText: {
			type: String,
			default: ''
		},
		delay: {
			type: Number,
			default: 250
		},
		id: {
			type: [Number, String],
			default: null
		}
	},

	setup(props, { emit }) {
		const controller = props.controller;
		const searchInput = ref(null);
		const searchValue = ref('');
		const items = ref([]);

		let handle = null;
		let close = false;
		let find = false;

		const table = controller.options?.reference ? controller.options.reference :
			(
				controller.typeahead?.table ? controller.typeahead.table : controller.structure.type?.reference ? controller.structure.type.reference : controller.field.value
			)

		//в DBStore добавить функцию представления
		const store = new DBStore(table);

		if (store.model && store.model.ownerField && controller.structure.depends) {
			store.owner = controller.store.getValue(controller.structure.depends)
		}

		const setPosition = (position) => {
			nextTick(() => {
				store.setPosition(position);

				if (items.value.length) {
					const item = items.value[position];

					item.focus();
				}
			});
		}

		const setPositionData = (data) => {
			const row = store.data.rows.findIndex((el) => el.id == data[props.valueField]);

			setPosition(row != -1 ? row : 0);
		}

		const onClick = (data) => {
			emit('set-data', data);
		}

		const clickawayClose = (event) => {
			event.preventDefault();

			const path = event.path || (event.composedPath ? event.composedPath() : undefined);

			close = false;

			if (path) {
				for (const item of path) {
					if (item?.classList?.contains('dropdown-item')) {
						close = true;

						emit('close', true);
					}
				}
			}

			setTimeout(() => close = true, 0);
		}

		const closePopup = () => {
			if (close) emit('close');
		}

		const keyDown = (e) => {
			const data = store.data;

			if (data.position != null) {
				switch (e.keyCode) {
					case 13: {
						e.preventDefault();

						if (data.position != -1) {
							emit('set-data', data.rows[data.position]);

							emit('close', true);
						}
					}

						break;

					case 38: {
						e.preventDefault();

						if (data.position > 0) {
							setPosition(data.position - 1);
						} else {
							if (searchInput.value) {
								if (document.activeElement == searchInput.value.input) {
									emit('close', true);
								} else {
									searchInput.value.input.focus();
								}
							} else {
								emit('close', true);
							}
						}
					}

						break;

					case 40: {
						e.preventDefault();

						if (data.position < data.rows.length - 1) {
							setPosition(data.position + 1);
						}
					}

						break;

					default:

						break;
				}
			}
		}

		const onKeyDownFind = (e) => {
			if (document.activeElement == searchInput.value.input) {
				if (find && e.keyCode == 13 && store.data.rows.length > 0) {
					onClick(store.data.rows[0]);

					closePopup();
				}
			}
		}

		const setFocus = () => {
			if (items.value.length > 0) {
				const firstItem = items.value[0];

				firstItem.focus();

				setPosition(0);
			}
		}

		const isSearchInput = computed(() => controller.props.selectMode && typeof controller.structure.type == 'object' && controller.structure.type.reference);

		onMounted(() => {
			document.addEventListener("click", closePopup);

			setTimeout(() => close = true, 0);
		})

		onUnmounted(() => {
			document.removeEventListener("click", closePopup);
		})

		if (typeof controller.structure.type == 'object') {
			if (controller.structure.type?.enum) {
				const config = {
					key: props.valueField,
					fields: {
						[props.valueField]: { type: 'SMALLINT' },
						[props.textField]: { type: 'STRING' }
					}
				}

				store.model = config;

				store.createState(config);

				const filter = controller.structure?.config?.options?.filter;

				if (filter && typeof filter == 'function') {
					store.loadData(filter(controller.structure.type.enum), false);
				} else {
					store.loadData(controller.structure.type.enum, false);
				}

				if (props.id) setPositionData({ id: props.id });

				store.model.subtable = true;
			} else if (controller.structure.type?.reference) {
				if (controller.props.selectMode) {
					const options = Object.assign(
						{
							limit: 20,
							fields: []
						},
						controller.structure?.config?.options ? controller.structure.config.options : {},
						controller.structure?.config?.typeahead?.options ? controller.structure.config.typeahead.options : {}
					);

					if (options.filters) {
						options.filters = typeof options.filters == 'function' ?
							options.filters(controller.data) :
							options.filters;
					}

					if (props.id) options['id'] = props.id;

					if (store?.model.ownerField && controller.structure.depends) {
						if (store.owner) store.fetchData(options, !!props.id);
					} else {
						store.fetchData(options, !!props.id);
					}

					watch(
						() => searchValue.value,
						(searchtext) => {
							setTimeout(() => find = true, 0);

							store.data.position = -1;

							const options = Object.assign(
								{
									searchtext,
									limit: 20,
									fields: []
								},
								controller.structure?.config?.options ? controller.structure.config.options : {},
								controller.structure?.config?.typeahead?.options ? controller.structure.config.typeahead.options : {}
							);

							if (options.filters) {
								options.filters = typeof options.filters == 'function' ?
									options.filters(controller.data) :
									options.filters;
							}

							store.fetchData(options, false);
						}
					)
				} else {
					watch(
						() => props.searchText,
						(searchtext) => {
							if (handle != null) clearTimeout(handle);

							handle = setTimeout(() => {
								const options = Object.assign(
									{
										searchtext,
										limit: 20,
										fields: []
									},
									controller.structure?.config?.options ? controller.structure.config.options : {},
									controller.structure?.config?.typeahead?.options ? controller.structure.config.typeahead.options : {}
								);

								if (options.filters) {
									options.filters = typeof options.filters == 'function' ?
										options.filters(controller.data) :
										options.filters;
								}

								if (store.model.ownerField && controller.structure.depends) {
									if (store.owner) store.fetchData(options, false)
								} else {
									store.fetchData(options, false)
								}

							}, props.delay)
						},
						{
							immediate: true
						}
					)
				}
			}
		} else if (typeof controller.structure.type == 'string') {
			if (controller.typeahead && controller.structure.type == 'STRING') {
				watch(
					() => props.searchText,
					(searchtext) => {
						if (!controller.props.typeahead) return;

						if (handle != null) clearTimeout(handle);

						handle = setTimeout(() => {
							const options = Object.assign(
								{
									searchtext,
									limit: 5,
									fields: []
								},
								controller.structure?.config?.options ? controller.structure.config.options : {},
								controller.structure?.config?.typeahead?.options ? controller.structure.config.typeahead.options : {}
							);

							if (options.filters) {
								options.filters = typeof options.filters == 'function' ? options.filters(controller.data) : options.filters;
							}

							store.fetchData(options, false)
								.then((response) => {
									controller.showPopup.value = response.length > 0;
								});
						}, props.delay)
					},
					{
						immediate: true
					}
				)
			}
		}

		const onCreate = async () => {
			await controller.addReference();
		}

		const notion = (data) => {
			const _data = typeof controller.structure?.config?.typeahead?.notion == 'function' ? controller.structure.config.typeahead.notion(data) : data[props.textField];

			return typeof _data == 'object'
				? _data
				: {
					notion: _data,
					title: _data
				};
		}

		return {
			onKeyDownFind,
			notion,
			items,
			data: store.data,
			onClick,
			onCreate,
			clickawayClose,
			keyDown,
			setFocus,
			searchValue,
			searchInput,
			isSearchInput,
			showCreateBtn: computed(() => store.model?.access?.create && controller.structure.type.reference),
			selectMode: computed(() => controller.props.selectMode),
			searchAway: computed(() => controller.props.searchAway)
		}
	}
})

</script>

<style scoped>
li .dropdown-item {
	white-space: inherit;
}

.no-found {
	width: 100%;
	padding: 2px 1rem 0px 1rem;
	clear: both;
	font-weight: 500;
	color: #333;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	position: relative;
}

.no-found-label {
	margin-right: 85px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn-create {
	position: absolute;
	right: 15px;
	top: 2px;
}

.ul-dropdown {
	font-size: 12px;
	color: #424242;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	padding: 0px;
	margin: 4px 0px 4px;
	max-height: 215px;
	overflow: hidden auto;
}

.v-dropdown-container {
	position: absolute;
	margin: 2px 0 0;
	width: 100%;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 0.25rem;
	border: 1px solid rgba(0, 0, 0, 0.15);
	z-index: 1000;
}
</style>