import dayjs from 'dayjs';
import { reactive } from 'vue';
import { genGUID, isArray, mergeDeep } from '@/helpers/utils';
import stateStore from "@/store";
import { ADMIN_ROLE } from '@/constants';
import { ResultResponse, query } from '@/api/db';

class Store {
	public owner: any = null;
	public state: any = reactive({});
	public files: any = {};

	public model: any = null;

	/**
	 * Создание состояния стора, для работы визуальных компонентов
	 */
	createState(config: any = {}) {
		if (this.model) {
			const state: any = { fields: {}, readonly: false, loaded: false };

			const _config = Object.assign({}, config);

			//Создание полей id, owner если они не найдены
			if (_config.fields) {
				if (this.model.key && !_config.fields[this.model.key]) {
					_config.fields[this.model.key] = {
						description: this.model.key,
						type: 'UUID',
						config: {
							visible: false,
							hide: true
						}
					};
				}

				if (this.model.ownerField && !_config.fields[this.model.ownerField]) {
					_config.fields[this.model.ownerField] = {
						description: this.model.ownerField,
						type: 'UUID',
						config: {
							visible: false,
							hide: true
						}
					};
				}

			}

			if (this.model.fields) {
				if (_config.fields && Object.keys(_config.fields).length > 0) {
					for (const key in _config.fields) {
						const field = this.model.fields[key] ? this.model.fields[key] : {};

						state.fields[key] = mergeDeep(
							{
								config: {
									visible: true,
									hide: false
								}
							},
							field,
							_config.fields[key]
						);

						if (!state.fields[key].description) state.fields[key].description = key;
					}
				} else {
					for (const key in this.model.fields) {
						const field = this.model.fields[key];

						state.fields[key] = mergeDeep(
							{
								config: {
									visible: true,
									hide: false
								}
							},
							field
						);

						if (!state.fields[key].description) state.fields[key].description = key;
					}
				}
			}

			Object.assign(this.state, state);

			if (!this.model.onDefaults) this.model.onDefaults = async () => ({});

			if (!this.model.onBeforeDelete) this.model.onBeforeDelete = async () => true;

			if (!this.model.onAfterDelete) this.model.onAfterDelete = async () => ({});

			if (!this.model.onBeforeSave) this.model.onBeforeSave = async () => true;

			if (!this.model.onAfterSave) this.model.onAfterSave = async () => ({});
		}
	}

	/**
	 * Подготовка объекта данных
	 * @param data 
	 * @returns 
	 */
	createData(data: any) {
		const newRow: any = {};

		if (this.model.key && !data[this.model.key]) newRow[this.model.key] = genGUID();

		for (const key in data) {
			if (key in this.model.fields) {
				const field = this.model.fields[key];

				//Загрузка объектных полей
				if (typeof field.type == 'object') {
					if (field.type.reference) {
						newRow[key] = data[key];
						newRow[`_${key}`] = data[`_${key}`];

						//Перечисление
					} else if (field.type.enum) {
						newRow[key] = data[key];

						if (data[`_${key}`]) {
							newRow[`_${key}`] = data[`_${key}`];
						} else {
							const _enum = field.type.enum.find((el: any) => el.id == data[key]);
							if (_enum) {
								newRow[`_${key}`] = _enum.name;
							}
						}

					} else if (field.type.sql) {
						newRow[key] = data[key];
					} else if (field.type.fields) {
						newRow[key] = data[key];
					}
				} else {
					//Примитивное поле
					if (field.type == 'DATE') {
						newRow[key] = data[key] ? dayjs(data[key]).format("YYYY-MM-DDTHH:mm:ss") : null;
					} else {
						newRow[key] = data[key];
					}
				}
			}
		}

		if (this.owner) newRow[this.model.ownerField] = this.owner;

		for (const key in data) {
			if (key in this.model.fields) {
				const field = this.model.fields[key];

				//Загрузка объектных полей
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {
						newRow[key] = [];

						const store = new Store;

						store.model = field.type.table;

						const config = this.state.fields[key] ? this.state.fields[key] : {};

						store.createState(config);

						if (store.model.ownerField && store.model.depends && data[store.model.depends]) store.owner = data[store.model.depends];

						const rows = Array.isArray(data[key]) ? data[key] : data[key].rows;

						for (const row of rows) {
							newRow[key].push(store.createData(row));
						}
					}
				}
			}
		}

		if (this.model.tree && data.children) {
			newRow.children = [];

			for (const children of data.children) {
				newRow.children.push(this.createData(children));
			}
		}

		return newRow;
	}

	/**
	 * Получение объекта данных без представлений
	 * @param data 
	 * @returns 
	 */
	getData(data: any) {
		const result: any = {};

		for (const key in data) {
			const field = this.model.fields[key];

			if (field) {
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {
						const store = new Store;
						store.model = field.type.table;

						result[key] = [];

						for (const row of data[key]) {
							result[key].push(store.getData(row));
						}

						//Ссылочное поле
					} else if (field.type.reference) {
						result[key] = data[key];

						//Перечисление
					} else if (field.type.enum) {
						result[key] = data[key];

						//Выбранное поле
					} else if (field.type.fields) {
						result[key] = data[key];
					}
				} else {
					if (field.type == 'DATE') {
						result[key] = data[key] ? dayjs(data[key]).format("YYYY-MM-DDTHH:mm:ss") : null;
					} else if (field.type == 'JSON') {
						result[key] = typeof data[key] == 'string' ? JSON.parse(data[key]) : data[key];
					} else {
						if ((field.type == 'INTEGER' || field.type == 'SMALLINT' || field.type == 'BIGINT' || field.type == 'DECIMAL') && data[key] === '') {
							data[key] = null;
						}

						result[key] = data[key];
					}
				}
			}
		}

		return result;
	}

	/**
	 * Получение полей для запроса
	 * @returns 
	 */
	getFetchFields() {
		const readFields = (fields: any): any[] => {
			const result = [];

			for (const key in fields) {
				const field = fields[key];

				if (typeof field.type === 'string') {
					result.push(key);
				} else if (field.fields) {
					const fields = readFields(field.fields);

					if (this.model.key && !fields.includes(this.model.key)) fields.push(this.model.key);
					if (this.model.ownerField && !fields.includes(this.model.ownerField)) fields.push(this.model.ownerField);

					result.push({
						[key]: {
							fields
						}
					})
				} else {
					result.push(key);
				}
			}

			return result;
		}

		return readFields(this.state.fields);
	}

	async notion(table: string, notionData: any = {}) {
		let getNotion = false;

		const getData = (data: any, model: any = null) => {
			for (const key in data) {
				const field = model ? model[key] : this.state.fields[key];

				if (field) {
					if (typeof field.type == 'object') {
						if (field.type.reference || field.type.sql) {
							getNotion = true;
						} else if (field.type.table) {
							for (const row of data[key]) {
								getData(row, field.type.table.fields);
							}
						}
					}
				}
			}
		}

		getData(notionData);

		if (getNotion) {
			const response: ResultResponse = await query({ table, method: 'notion', data: notionData });

			if (response.complete) {
				return response.data;
			}
		}

		return notionData;
	}

	accessCreate() {
		return this.model.access.create || this.availabeRole(ADMIN_ROLE);
	}

	accessRead() {
		return this.model.access.read || this.availabeRole(ADMIN_ROLE);
	}

	accessUpdate() {
		return this.model.access.update || this.availabeRole(ADMIN_ROLE);
	}

	accessDelete() {
		return this.model.access.delete || this.availabeRole(ADMIN_ROLE);
	}

	availabeRole(value: any, field: any = null, structure: any = null, data: any = null) {
		const roles: string[] = stateStore.state.user.roles;

		const _value = typeof value == 'function' ? value(field, structure, data) : value;

		if (typeof _value == 'boolean') {

			return _value || roles.includes(ADMIN_ROLE);

		} else if (typeof _value == 'string') {

			return roles.includes(_value) || roles.includes(ADMIN_ROLE);

		} else if (isArray(_value)) {

			for (const role of _value) {
				if (roles.includes(role) || roles.includes(ADMIN_ROLE)) return true;
			}

		}

		return false;
	}
}

export default Store;
