import access from "./access";
import accounts from "./accounts";
import address_districts from "./address_districts";
import address_regions from "./address_regions";
import address_village_councils from "./address_village_councils";
import address_locality from "./address_locality";
import address_street from "./address_street";
import api_test from "./api_test";
import autos from "./autos";
import banks from "./banks";
import binding_regions from "./binding_regions";
import brigades from "./brigades";
import brigades_list from "./brigades_list";
import call_log from "./call_log";
import call_log_templates from "./call_log_templates";
import call_place from "./call_place";
import connected_equipment from "./connected_equipment";
import contacts from "./contacts";
import contacts_types from "./contacts_types";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import country from "./country";
import demo from "./demo";
import divisions_mvd from "./divisions_mvd";
import document_types from "./document_types";
import employees from "./employees";
import files from "./files";
import files_versions from "./files_versions";
import fizlica from "./fizlica";
import expertise from "./expertise";
import history_changes from "./history_changes";
import icd10 from "./icd10";
import mailing from "./mailing";
import messages from "./messages";
import nomenclature from "./nomenclature";
import numerators from "./numerators";
import offline from "./offline";
import orders from "./orders";
import patients from "./patients";
import positions from "./positions";
import posts from "./posts";
import reasons from "./reasons";
import roles from "./roles";
import subscribers from "./subscribers";
import templates from "./templates";
import test from "./test";
import units from "./units";
import users from "./users";
import users_roles from "./users_roles";
import injections from "./injections";
import release_form from "./release_form";
import reports_types from "./reports_types";
import medicaments from "./medicaments";
import webinars from "./webinars";
import form_stat from "./form_stat";
import form1 from "./form1";

import { mergeDeep } from "@/helpers/utils";

const tables: any = {
	access,
	accounts,
	address_districts,
	address_regions,
	address_village_councils,
	address_locality,
	address_street,
	api_test,
	autos,
	banks,
	binding_regions,
	brigades,
	brigades_list,
	call_log,
	call_log_templates,
	call_place,
	connected_equipment,
	contacts,
	contacts_types,
	contragents,
	'contragents.departments': contragents_departments,
	country,
	demo,
	divisions_mvd,
	document_types,
	employees,
	expertise,
	files,
	files_versions,
	fizlica,
	form1,
	history_changes,
	icd10,
	mailing,
	messages,
	nomenclature,
	numerators,
	offline,
	orders,
	patients,
	positions,
	posts,
	reasons,
	roles,
	subscribers,
	templates,
	test,
	units,
	users,
	'users.roles': users_roles,
	injections,
	release_form,
	reports_types,
	medicaments,
	webinars,
	form_stat
}

export const schema: any = {};

export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		const table: any = tables[key] ? tables[key] : {};

		schema[key] = Object.assign({}, dbSchema[key], table);
	}

	for (const keySchema in schema) {
		const structure = schema[keySchema];

		const fields = structure.fields;

		const key = structure.key;

		if (!fields[key]) {
			fields[key] = {
				[key]: {
					description: 'id',
					type: 'UUID'
				}
			}
		}

		for (const keyField in fields) {
			const field = fields[keyField];

			if (typeof field.type == 'object') {
				if (field.type.table && typeof field.type.table == 'string') {
					field.table = field.type.table;
					field.type.table = schema[field.type.table];
					field.type.table.subtable = true;
					field.ownerField = field.type.table.ownerField;
				}
			}
		}
	}

	// console.log(schema);
}

export const getModel = (name: string) => {
	if (name) {
		const model = schema[name];
		if (model) {
			const _model = mergeDeep({}, model);

			//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
			if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

			return _model;
		} else {
			return tables[name] ? mergeDeep({ offLine: true }, tables[name]) : null;
		}
	} else {
		return null
	}
}
